import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  card: {
    width: 'full',
    border: 'none',
    px: '12px',
    py: '18px',
    borderRadius: '16px',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column'
    }
  },
  createInfoStack: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '10px'
  },
  createInfoInnerStack: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px'
  },
  componentInfoText: {
    fontSize: '19px',
    fontWeight: 600
  },
  createdDateText: {
    color: caloTheme.palette.neutral900,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px'
  },
  createdByText: {
    fontWeight: 600
  },
  textFieldStack: {
    width: '80%',
    mx: 2
  },
  nameInputStack: {
    mt: 3,
    mb: 2,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  textField: {
    width: '50%',
    mr: 2
  },
  textFieldInputProps: {
    inputProps: { style: { borderRadius: '16px' } },
    style: { borderRadius: '16px' }
  },
  allergensStack: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    mt: '12px'
  },
  ingredientChips: {
    fontWeight: 600
  },
  ingredientChip: {
    color: caloTheme.palette.red,
    backgroundColor: caloTheme.palette.secondaryPink100,
    fontSize: '14px',
    mb: '15px'
  }
};
