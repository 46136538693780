import { Permission } from '@calo/dashboard-types';
import CaloLoader from 'components/CaloLoader';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import AddonsList from './AddonsMenu/AddonsList';
import ExactAddons from './AddonsMenu/ExactAddons';
import NewAddons from './AddonsMenu/NewAddons';
import AddonsSubscriptionList from './AddonsSubscription/AddonsSubscriptionList';
import ExactAddonsSubscription from './AddonsSubscription/ExactAddonsSubscription';
import ExactMenu from './MainMenu/ExactMenu';
import MenuList from './MainMenu/MenuList';
import NewMenu from './MainMenu/NewMenu';
import ExactPackage from './Packages/ExactFoodPackages';
import ExactPackageElement from './Packages/ExactPackageElement';
import NewFoodPackages from './Packages/NewFoodPackages';
import NewPackageElement from './Packages/NewPackageElement';
import PackageElementList from './Packages/PackageElementList';
import PackagesList from './Packages/PackagesList';

const Menu = () => {
  const roles = useUserRoles();
  return (
    <Suspense fallback={<CaloLoader />}>
      <Switch>
        {roles.includes(Permission.VIEW_MENU_LIST) && [
          <Route key={Routes.menuList} exact path={Routes.menuList} component={MenuList} />
        ]}
        {roles.includes(Permission.VIEW_MENU) && [<Route exact path={Routes.menu} component={ExactMenu} />]}
        {roles.includes(Permission.CREATE_MENU) && [
          <Route key={Routes.newMenu} exact path={Routes.newMenu} component={NewMenu} />
        ]}
        {roles.includes(Permission.VIEW_ADDONS_LIST) && (
          <Route key={Routes.addonsList} exact path={Routes.addonsList} component={AddonsList} />
        )}
        {roles.includes(Permission.CREATE_ADDONS) && (
          <Route key={Routes.newAddons} exact path={Routes.newAddons} component={NewAddons} />
        )}
        {roles.includes(Permission.VIEW_ADDONS) && (
          <Route key={Routes.addons} exact path={Routes.addons} component={ExactAddons} />
        )}
        {roles.includes(Permission.VIEW_ADDONS_SUBSCRIPTION_LIST) && (
          <Route
            key={Routes.addonsSubscriptionList}
            exact
            path={Routes.addonsSubscriptionList}
            component={AddonsSubscriptionList}
          />
        )}
        {roles.includes(Permission.UPDATE_ADDONS_SUBSCRIPTION) && (
          <Route key={Routes.addonsSubscription} exact path={Routes.addonsSubscription} component={ExactAddonsSubscription} />
        )}
        {roles.includes(Permission.VIEW_FOOD_PACKAGE_LIST) && (
          <Route key={Routes.packagesList} exact path={Routes.packagesList} component={PackagesList} />
        )}
        {roles.includes(Permission.CREATE_FOOD_PACKAGE) && (
          <Route key={Routes.newPackage} exact path={Routes.newPackage} component={NewFoodPackages} />
        )}
        {roles.includes(Permission.VIEW_FOOD_PACKAGE) && (
          <Route key={Routes.package} exact path={Routes.package} component={ExactPackage} />
        )}
        {roles.includes(Permission.VIEW_FOOD_PACKAGE_ELEMENT_LIST) && (
          <Route key={Routes.packageElementList} exact path={Routes.packageElementList} component={PackageElementList} />
        )}
        {roles.includes(Permission.CREATE_FOOD_PACKAGE_ELEMENT) && (
          <Route key={Routes.newPackageElement} exact path={Routes.newPackageElement} component={NewPackageElement} />
        )}
        {roles.includes(Permission.VIEW_FOOD_PACKAGE_ELEMENT) && (
          <Route key={Routes.packageElement} exact path={Routes.packageElement} component={ExactPackageElement} />
        )}
      </Switch>
    </Suspense>
  );
};

export default Menu;
