import { caloTheme } from 'assets/images/theme/calo';
import { Auth } from 'aws-amplify';
import cx from 'classnames';
import { Routes } from 'lib/enums';
import { useCurrentUser } from 'lib/hooks';
import { selectUserRoles } from 'lib/selectors';
import { Fragment, useMemo } from 'react';
import { generatePath, matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button } from '@mui/material';

import Icon from '../Icon';

interface SidebarProps {
  isOpen: boolean;
}

const Sidebar = ({ isOpen }: SidebarProps) => {
  const user = useCurrentUser();
  const location = useLocation();
  const roles = selectUserRoles(user);
  const path = generatePath(Routes.menuList, { brand: Brand.CALO, kitchen: Kitchen.BH1 });

  const list = useMemo(() => {
    const res = [
      ...([
        Permission.LIST_CUSTOMER_COMPLAINT,
        Permission.VIEW_SUBSCRIPTION_LIST,
        Permission.VIEW_DELIVERY_LIST,
        Permission.LIST_GROUP_GIFT
      ].some((r) => roles.includes(r))
        ? [
            {
              title: 'Subscribers',
              items: [
                // ...(roles.includes(Permission.VIEW_SUBSCRIPTION_LIST) ? [{
                //   title: 'Subscriptions',
                //   route: Routes.subscriptions,
                //   isActive: matchPath(location.pathname, { path: Routes.subscriptions, exact: true })
                // }] : []),
                ...(roles.includes(Permission.LIST_APP_COGNITO_USERS)
                  ? [
                      {
                        title: 'Users',
                        route: Routes.subscriptionUsers,
                        isActive: matchPath(location.pathname, { path: Routes.subscriptionUsers, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_SUBSCRIPTION_LIST)
                  ? [
                      {
                        title: 'Subscriptions',
                        route: Routes.subscriptions,
                        isActive: matchPath(location.pathname, { path: Routes.subscriptions, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_DELIVERY_LIST)
                  ? [
                      {
                        title: 'Deliveries',
                        route: Routes.deliveries,
                        isActive: matchPath(location.pathname, { path: Routes.deliveries, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.LIST_GROUP_GIFT)
                  ? [
                      {
                        title: 'Group Action',
                        route: Routes.gifts,
                        isActive: matchPath(location.pathname, { path: Routes.gifts, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.LIST_CUSTOMER_COMPLAINT)
                  ? [
                      {
                        title: 'Complaints',
                        route: Routes.complaints,
                        isActive: matchPath(location.pathname, { path: Routes.complaints, exact: true })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),
      {
        title: 'Plans',
        items: [
          {
            title: 'Calculator',
            route: Routes.plansCalculator,
            isActive: matchPath(location.pathname, { path: Routes.plansCalculator, exact: true })
          }
        ]
      },
      ...([Permission.HEALTH_CONDITIONS_PLAN_MANAGEMENT].some((r) => roles.includes(r))
        ? [
            {
              title: 'Nutritionists',
              items: [
                ...(roles.includes(Permission.HEALTH_CONDITIONS_PLAN_MANAGEMENT)
                  ? [
                      {
                        title: 'Meal Planning',
                        route: Routes.nutritionists,
                        isActive: matchPath(location.pathname, { path: Routes.nutritionists, exact: true })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),

      ...([Permission.VIEW_BUSINESS_USER_LIST, Permission.VIEW_BUSINESS_DELIVERY_LIST].some((r) => roles.includes(r))
        ? [
            {
              title: 'Business',
              items: [
                ...(roles.includes(Permission.VIEW_BUSINESS_USER_LIST)
                  ? [
                      {
                        title: 'Accounts',
                        route: Routes.accountUsersList,
                        isActive: matchPath(location.pathname, { path: Routes.accountUsersList, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_BUSINESS_DELIVERY_LIST)
                  ? [
                      {
                        title: 'Orders',
                        route: Routes.orders,
                        isActive: matchPath(location.pathname, { path: Routes.orders, exact: true })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),
      ...([Permission.VIEW_ROUTE_PLAN_LIST, Permission.VIEW_DELIVERY_LIST, Permission.VIEW_TICKET_LIST].some((r) =>
        roles.includes(r)
      )
        ? [
            {
              title: 'Logistics',
              items: [
                ...(roles.includes(Permission.VIEW_TICKET_LIST)
                  ? [
                      {
                        title: 'Updates',
                        route: Routes.tickets,
                        isActive: matchPath(location.pathname, { path: Routes.tickets, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_ROUTE_PLAN_LIST)
                  ? [
                      {
                        title: 'Delivery Planner',
                        route: Routes.deliveryPlanner,
                        isActive: matchPath(location.pathname, { path: Routes.deliveryPlanner, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_DRIVER_COOLER_BAG_STATS)
                  ? [
                      {
                        title: 'Cooler Bags',
                        route: Routes.coolerBags,
                        isActive: matchPath(location.pathname, { path: Routes.coolerBags, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_DRIVER_METRIC_LIST)
                  ? [
                      {
                        title: 'Drivers Metrics',
                        route: Routes.driversMetric,
                        isActive: matchPath(location.pathname, { path: Routes.driversMetric, exact: true })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),

      ...([Permission.LIST_DELIVERY_TICKET].some((r) => roles.includes(r))
        ? [
            {
              title: 'LockTime Changes',
              items: [
                ...(roles.includes(Permission.LIST_DELIVERY_TICKET)
                  ? [
                      {
                        title: 'Operations Changes',
                        route: Routes.operationsChanges,
                        isActive: matchPath(location.pathname, { path: Routes.operationsChanges, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.LIST_DELIVERY_TICKET)
                  ? [
                      {
                        title: 'Logistics Changes',
                        route: Routes.logisticChange,
                        isActive: matchPath(location.pathname, { path: Routes.logisticChange, exact: true })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),

      ...([Permission.LIST_GIFTS].some((r) => roles.includes(r))
        ? [
            {
              title: 'Special Requests',
              items: [
                ...(roles.includes(Permission.LIST_GIFTS)
                  ? [
                      {
                        title: 'Gifts',
                        route: Routes.specialRequest,
                        isActive: matchPath(location.pathname, { path: Routes.specialRequest, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_EMPLOYEE_PERKS)
                  ? [
                      {
                        title: 'Employee Perks',
                        route: Routes.employeePerks,
                        isActive: matchPath(location.pathname, { path: Routes.employeePerks, exact: true })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),

      ...([
        Permission.VIEW_PROTOTYPE_FOOD_LIST,
        Permission.VIEW_PROTOTYPE_FOOD,
        Permission.VIEW_PROTOTYPE_COMPONENT,
        Permission.VIEW_PROTOTYPE_COMPONENT_LIST
      ].some((r) => roles.includes(r))
        ? [
            {
              title: 'Chef Playground',
              items: [
                ...(roles.includes(Permission.VIEW_PROTOTYPE_FOOD_LIST)
                  ? [
                      {
                        title: 'Meals',
                        route: Routes.playgroundFoodList,
                        isActive: matchPath(location.pathname, { path: Routes.playgroundFoodList, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_PROTOTYPE_COMPONENT_LIST)
                  ? [
                      {
                        title: 'Components',
                        route: Routes.playgroundComponentList,
                        isActive: matchPath(location.pathname, { path: Routes.playgroundComponentList, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_PROTOTYPE_INGREDIENT_LIST)
                  ? [
                      {
                        title: 'Ingredients',
                        route: Routes.playgroundIngredientList,
                        isActive: matchPath(location.pathname, { path: Routes.playgroundIngredientList, exact: true })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),

      ...([
        Permission.SEND_KITCHEN_EMAIL,
        Permission.VIEW_KITCHEN_LIST,
        Permission.VIEW_SUPPLY_CAP_ZONES,
        Permission.VIEW_FOOD_STATS,
        Permission.VIEW_PORTION_STATS,
        Permission.VIEW_INGREDIENT_STATS
      ].some((r) => roles.includes(r))
        ? [
            {
              title: 'Kitchen',
              items: [
                ...(roles.includes(Permission.SEND_KITCHEN_EMAIL)
                  ? [
                      {
                        title: 'Dashboard',
                        route: Routes.root,
                        isActive: matchPath(location.pathname, { path: Routes.root, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_KITCHEN_LIST)
                  ? [
                      {
                        title: 'Kitchens',
                        route: Routes.kitchenList,
                        isActive: matchPath(location.pathname, { path: Routes.kitchenList, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_SUPPLY_CAP_ZONES)
                  ? [
                      {
                        title: 'Supply Cap Zones',
                        route: Routes.supplyCapZones,
                        isActive: matchPath(location.pathname, { path: Routes.supplyCapZones, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_PORTION_STATS)
                  ? [
                      {
                        title: 'Meal Stats',
                        route: Routes.kitchenPortionStats,
                        isActive: matchPath(location.pathname, { path: Routes.kitchenPortionStats, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_FOOD_STATS)
                  ? [
                      {
                        title: 'Component Stats',
                        route: Routes.kitchenFoodStats,
                        isActive: matchPath(location.pathname, { path: Routes.kitchenFoodStats, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_INGREDIENT_STATS)
                  ? [
                      {
                        title: 'Ingredient Stats',
                        route: Routes.kitchenIngredientStats,
                        isActive: matchPath(location.pathname, { path: Routes.kitchenIngredientStats, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_PACKAGE_ELEMENTS_STATS)
                  ? [
                      {
                        title: 'Packaging Stats',
                        route: Routes.packagingStats,
                        isActive: matchPath(location.pathname, { path: Routes.packagingStats, exact: true })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),

      ...([Permission.VIEW_MENU_LIST, Permission.VIEW_ADDONS_LIST, Permission.VIEW_ADDONS_SUBSCRIPTION_LIST].some((r) =>
        roles.includes(r)
      )
        ? [
            {
              title: 'Menu',
              items: [
                ...(roles.includes(Permission.VIEW_MENU_LIST)
                  ? [
                      {
                        title: 'Main',
                        route: path,
                        isActive: matchPath(location.pathname, { path: path, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_ADDONS_LIST)
                  ? [
                      {
                        title: 'Addons',
                        route: Routes.addonsList.replace(':country', Country.BH),
                        isActive: matchPath(location.pathname, {
                          path: Routes.addonsList.replace(':country', Country.BH),
                          exact: true
                        })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_ADDONS_SUBSCRIPTION_LIST)
                  ? [
                      {
                        title: 'Addons Subscription',
                        route: Routes.addonsSubscriptionList.replace(':country', Country.BH),
                        isActive: matchPath(location.pathname, {
                          path: Routes.addonsSubscriptionList.replace(':country', Country.BH),
                          exact: true
                        })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),

      ...([
        Permission.VIEW_KITCHEN_LOGS_LIST,
        Permission.VIEW_FOOD_LIST,
        Permission.VIEW_FOOD_COMPONENTS_LIST,
        Permission.VIEW_INGREDIENT_LIST,
        Permission.VIEW_FOOD_PACKAGE_LIST,
        Permission.VIEW_RATING_LIST
      ].some((r) => roles.includes(r))
        ? [
            {
              title: 'Food',
              items: [
                ...(roles.includes(Permission.VIEW_FOOD_LIST)
                  ? [
                      {
                        title: 'Meals',
                        route: Routes.foodList,
                        isActive: matchPath(location.pathname, { path: Routes.foodList, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_FOOD_COMPONENTS_LIST)
                  ? [
                      {
                        title: 'Components',
                        route: Routes.foodComponentList,
                        isActive: matchPath(location.pathname, { path: Routes.foodComponentList, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_INGREDIENT_LIST)
                  ? [
                      {
                        title: 'Ingredients',
                        route: Routes.ingredients,
                        isActive: matchPath(location.pathname, { path: Routes.ingredients, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_FOOD_PACKAGE_LIST)
                  ? [
                      {
                        title: 'Packaging',
                        route: Routes.packagesList,
                        isActive: matchPath(location.pathname, { path: Routes.packagesList, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_FOOD_PACKAGE_ELEMENT_LIST)
                  ? [
                      {
                        title: 'Packaging Elements',
                        route: Routes.packageElementList,
                        isActive: matchPath(location.pathname, { path: Routes.packageElementList, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_RATING_LIST)
                  ? [
                      {
                        title: 'Feedback',
                        route: Routes.feedback,
                        isActive: matchPath(location.pathname, { path: Routes.feedback, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_KITCHEN_LOGS_LIST)
                  ? [
                      {
                        title: 'Food Logs',
                        route: Routes.foodLogs,
                        isActive: matchPath(location.pathname, { path: Routes.foodLogs, exact: true })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),

      ...([
        Permission.VIEW_USER_LIST,
        Permission.VIEW_USER_PERMISSIONS_LIST,
        Permission.VIEW_DRIVER_LIST,
        Permission.VIEW_KDS_USER_LIST
      ].some((r) => roles.includes(r))
        ? [
            {
              title: 'Users',
              items: [
                ...(roles.includes(Permission.VIEW_USER_LIST)
                  ? [
                      {
                        title: 'Users',
                        route: Routes.usersList,
                        isActive: matchPath(location.pathname, { path: Routes.usersList, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_DRIVER_LIST)
                  ? [
                      {
                        title: 'Drivers',
                        route: Routes.drivers,
                        isActive: matchPath(location.pathname, { path: Routes.drivers, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_KDS_USER_LIST)
                  ? [
                      {
                        title: 'KDS Users',
                        route: Routes.kdsUsersList,
                        isActive: matchPath(location.pathname, { path: Routes.kdsUsersList, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_USER_PERMISSIONS_LIST)
                  ? [
                      {
                        title: 'Permissions',
                        route: Routes.permissions,
                        isActive: matchPath(location.pathname, { path: Routes.permissions, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_KDS_PERMISSIONS_LIST)
                  ? [
                      {
                        title: 'KDS Permissions',
                        route: Routes.kdsPermissions,
                        isActive: matchPath(location.pathname, { path: Routes.kdsPermissions, exact: true })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),

      ...([Permission.VIEW_SUPPLY_CHAIN_USER_LIST, Permission.VIEW_SUPPLY_CHAIN_REPORTS_LIST].some((r) => roles.includes(r))
        ? [
            {
              title: 'Supply Chain',
              items: [
                ...(roles.includes(Permission.VIEW_SUPPLY_CHAIN_USER_LIST)
                  ? [
                      {
                        title: 'Users',
                        route: Routes.supplyChainUsersList,
                        isActive: matchPath(location.pathname, { path: Routes.supplyChainUsersList, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_SUPPLY_CHAIN_REPORTS_LIST)
                  ? [
                      {
                        title: 'Reports',
                        route: Routes.supplyChainReportsList,
                        isActive: matchPath(location.pathname, { path: Routes.supplyChainReportsList, exact: true })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),

      ...([
        Permission.VIEW_REFUND_LIST,
        Permission.VIEW_COUPON_LIST,
        Permission.VIEW_WALLET_LIST,
        Permission.VIEW_INVITATION_CODE_USER_STATS,
        Permission.VIEW_INVITATION_CODE_STATS
      ].some((r) => roles.includes(r))
        ? [
            {
              title: 'Payments',
              items: [
                ...(roles.includes(Permission.VIEW_WALLET_LIST)
                  ? [
                      {
                        title: 'Payment',
                        route: Routes.payment,
                        isActive: matchPath(location.pathname, { path: Routes.payment, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_REFUND_LIST)
                  ? [
                      {
                        title: 'Refunds',
                        route: Routes.refunds,
                        isActive: matchPath(location.pathname, { path: Routes.refunds, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_COUPON_LIST)
                  ? [
                      {
                        title: 'Coupons',
                        route: Routes.couponsList,
                        isActive: matchPath(location.pathname, { path: Routes.couponsList, exact: true })
                      }
                    ]
                  : []),
                ...(roles.includes(Permission.VIEW_INVITATION_CODE_USER_STATS) ||
                roles.includes(Permission.VIEW_INVITATION_CODE_STATS)
                  ? [
                      {
                        title: 'Invitation stats',
                        route: Routes.invitationStats,
                        isActive: matchPath(location.pathname, { path: Routes.invitationStats, exact: true })
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),
      ...([Permission.VIEW_AFFILIATE_PROFILE, Permission.CREATE_AFFILIATE_PROFILE, Permission.PAY_AFFILIATE_COMMISSION].some(
        (r) => roles.includes(r)
      )
        ? [
            {
              title: 'Affiliate Program',
              items: [
                {
                  title: 'Affiliates',
                  route: Routes.affiliateProfilesList,
                  isActive: matchPath(location.pathname, { path: Routes.affiliateProfilesList, exact: true })
                }
              ]
            }
          ]
        : [])
    ];
    return res;
  }, [location.pathname]);

  return (
    <div
      className={cx('flex items-center justify-center py-6 fixed left-0 top-0 bottom-0 overflow-y-auto', {
        'w-0 hidden': !isOpen,
        'w-56': isOpen
      })}
    >
      <ul className="flex flex-col w-full h-full">
        <Box sx={{ textAlign: 'start' }}>
          <Icon name="calo" size={8} className="w-32 -ml-8 mb-4" />
        </Box>
        {list.map(
          (row, i) =>
            row.items && (
              <Fragment key={i}>
                <li className="my-px">
                  <span
                    className="flex text-black text-lg px-4 my-2 uppercase font-roboto"
                    style={{ fontSize: '18px', fontWeight: 600 }}
                  >
                    {row.title}
                  </span>
                </li>
                {row.items.map((r, j) => (
                  <li className=" mx-2" key={j}>
                    <Link
                      to={r.route}
                      className={cx('flex flex-row items-center h-12  rounded-lg text-black', {
                        'hover:bg-cGreen bg-cGreen text-white hover:text-white ': r.isActive,
                        'hover:bg-lightGreen  text-black hover:text-cGreen ': !r.isActive
                      })}
                    >
                      <span className="ml-3" data-test={`${r.title}Tab`}>
                        {r.title}
                      </span>
                    </Link>
                  </li>
                ))}
              </Fragment>
            )
        )}
        <Button
          endIcon={<LogoutIcon />}
          onClick={() => Auth.signOut()}
          sx={{
            color: caloTheme.palette.neutral900,
            fontSize: '20px',
            marginTop: '10px',
            paddingBottom: '10px',
            textTransform: 'none',
            fontWeight: 600,
            borderRadius: 0,
            borderTop: '1px solid' + caloTheme.palette.neutral100
          }}
        >
          Sign Out
        </Button>
      </ul>
    </div>
  );
};

export default Sidebar;
