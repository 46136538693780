import {
  cancelDelivery,
  getInvoiceForDelivery,
  getRecord,
  regenerateDeliveryMenu,
  skipDelivery,
  uncancelDelivery,
  unskipDelivery,
  updateDelivery
} from 'actions';
import cx from 'classnames';
import { Button, Icon, ModalRef, PhoneNumber } from 'components';
import { format } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import { createAutoDownloadLinkFromUrl } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Delivery } from 'lib/interfaces';
import { Suspense, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AddressService } from 'services';

import { Permission } from '@calo/dashboard-types';
import { PermissionService } from '@calo/services';
import { Brand, DDeliveryStatus, DeliveryAddress, DeliveryStatus, DeliveryTime, PaymentMethod } from '@calo/types';

import AddressTab from './AddressTab';
import DeliverySettingsCard from './DeliverySettingsCard';
import MenuTab from './MenuTab';

const getInvoiceAsPDF = async (deliveryId: string) => {
  const response = await getInvoiceForDelivery(deliveryId);
  if (response.status === 200) {
    createAutoDownloadLinkFromUrl(response.data.data);
  }
};

const ExactDelivery = () => {
  const { id } = useParams<{ id: string }>();
  const roles = useUserRoles();

  const { data, refetch } = useQuery(['deliveries', id], getRecord, {
    suspense: true
  });

  const delivery = data as Delivery & { deliveryAddresses: DeliveryAddress[] };

  const menuModalRef = useRef<ModalRef>();

  const { mutateAsync: skipMutation } = useMutation(skipDelivery);
  const { mutateAsync: unskipMutation } = useMutation(unskipDelivery);
  const { mutateAsync: updateMutation } = useMutation(updateDelivery);
  const { mutateAsync: cancelMutation } = useMutation(cancelDelivery);
  const { mutateAsync: uncancelMutation } = useMutation(uncancelDelivery);

  const { mutateAsync: regenerateMutation } = useMutation(regenerateDeliveryMenu);

  return delivery ? (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  <Link to={Routes.deliveries}>Deliveries {delivery.country} </Link>
                </li>
                <li>
                  {delivery.day} / {delivery.kitchen}
                </li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {delivery.brand && delivery.brand === Brand.MEALO ? (
                <Icon name="mealo" size={28} className="w-auto -mb-24" />
              ) : (
                <Icon name="calo" size={8} className="w-32 -mb-4" />
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="mx-2 my-8">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <div className="w-full flex">
                <div className="flex">
                  <span className="mr-2 text-3xl font-bold">{delivery.day}</span>

                  <span
                    className={cx('tag mr-2 mt-2', {
                      'is-dark': delivery.time === DeliveryTime.evening,
                      'is-light': delivery.time !== DeliveryTime.evening
                    })}
                  >
                    {delivery.time || DeliveryTime.morning}
                  </span>

                  {delivery.deliveredAt ? (
                    <span className="text-lg text-green-500">
                      Delivered
                      <span className="ml-3 text-sm text-black">
                        ({format('hh:mm a')(new Date(Date.parse(delivery.deliveredAt)))})
                      </span>
                    </span>
                  ) : (
                    <span className="text-lg text-yellow-500 mt-1">Pending</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item buttons">
              {delivery.status !== DeliveryStatus.cancelled &&
                delivery.skipped &&
                PermissionService.deliveryCanBeEdited(delivery) &&
                roles.includes(Permission.UNSKIP_DELIVERY) && (
                  <Button
                    warning={!delivery.skipped}
                    primary={delivery.skipped}
                    onClick={() => unskipMutation(delivery.id)}
                    // loading={isSubmitting}
                    // disabled={!dirty || !isValid || isSubmitting}
                    content={'Unskip delivery'}
                  />
                )}
              {delivery.status !== DeliveryStatus.cancelled &&
                !delivery.skipped &&
                PermissionService.deliveryCanBeEdited(delivery) &&
                roles.includes(Permission.SKIP_DELIVERY) && (
                  <Button
                    warning={!delivery.skipped}
                    primary={delivery.skipped}
                    onClick={() => !delivery!.skipped && skipMutation(delivery.id)}
                    // loading={isSubmitting}
                    // disabled={!dirty || !isValid || isSubmitting}
                    content={'Skip delivery'}
                  />
                )}
              {delivery.status === DeliveryStatus.paused && roles.includes(Permission.UPDATE_DELIVERY) && (
                <Button
                  className="has-tooltip-primary"
                  primary
                  content="Resume delivery"
                  onClick={() => updateDelivery({ status: DeliveryStatus.paymentRequired, id: delivery.id })}
                  data-tooltip="This will resume this delivery"
                />
              )}
              {roles.includes(Permission.REGENERATE_DELIVERY_MENU) && (
                <>
                  <Button
                    className="has-tooltip-danger"
                    warning
                    content="Custom macros"
                    onClick={() => regenerateMutation({ id: delivery.id, isCustomMacros: true })}
                    data-tooltip="If you don't know what is this, you don't need it"
                    // disabled={delivery.food?.length == 0}
                  />
                  <Button
                    className="has-tooltip-danger"
                    warning
                    content="Regenerate menu"
                    onClick={() => regenerateMutation({ id: delivery.id, isCustomMacros: false })}
                    data-tooltip="This will replace whole menu"
                    // disabled={delivery.food?.length == 0}
                  />
                </>
              )}
              {delivery.status !== DeliveryStatus.cancelled && roles.includes(Permission.CANCEL_DELIVERY) && (
                <Button
                  className="has-tooltip-danger"
                  // disabled={!date || loading}
                  // loading={loading}
                  danger
                  content="Cancel delivery"
                  onClick={() => cancelMutation(delivery.id)}
                  data-tooltip="You cannot undo this action"
                />
              )}
              {delivery.status !== DeliveryStatus.cancelled && roles.includes(Permission.GET_DELIVERY_INVOICE) && (
                <Button
                  className="has-tooltip-primary"
                  primary
                  content="Get invoice"
                  onClick={() => getInvoiceAsPDF(delivery.id)}
                  data-tooltip="This will retrieve invoice as pdf file"
                />
              )}

              {delivery.status === DeliveryStatus.cancelled && roles.includes(Permission.UNCANCEL_DELIVERY) && (
                <Button className="has-tooltip-danger" danger content="Uncancel delivery" onClick={() => uncancelMutation(id)} />
              )}
            </div>
          </div>
        </div>
        <div className="-ml-2 -mt-8 mb-4">
          {delivery.paymentMethod === PaymentMethod.cash && (
            <span
              className="fa-stack mt-4 "
              title={delivery.status === DeliveryStatus.upcoming ? 'Paid by cash' : 'Not paid (cash)'}
            >
              <i className="fas fa-money-bill fa-stack-1x" />
              {delivery.status !== DeliveryStatus.upcoming && <i className="fas fa-slash fa-stack-2x" style={{ color: 'red' }} />}
            </span>
          )}
          {delivery.paymentMethod === PaymentMethod.cc && (
            <span
              className="fa-stack mt-4"
              title={delivery.status === DeliveryStatus.upcoming ? 'Paid by credit card' : 'Not paid (credit card)'}
            >
              <i className="fas fa-credit-card fa-stack-1x" />
              {delivery.status !== DeliveryStatus.upcoming && <i className="fas fa-slash fa-stack-2x" style={{ color: 'red' }} />}
            </span>
          )}
          {delivery.paymentMethod === PaymentMethod.benefit && (
            <span
              className="fa-stack mt-4"
              title={delivery.status === DeliveryStatus.upcoming ? 'Paid by Benefit' : 'Not paid (Benefit)'}
            >
              <i className="far fa-credit-card fa-stack-1x" />
              {delivery.status !== DeliveryStatus.upcoming && <i className="fas fa-slash fa-stack-2x" style={{ color: 'red' }} />}
            </span>
          )}
          {delivery.deliveryStatus === DDeliveryStatus.delivered && (
            <span
              className="fa-stack mt-4"
              title={delivery.deliveryStatus === DDeliveryStatus.delivered ? 'Deliveried' : 'Not Delivered'}
            >
              <i className="fas fa-truck fa-stack-1x" />
              {delivery.deliveryStatus !== DDeliveryStatus.delivered && (
                <i className="fas fa-slash fa-stack-2x" style={{ color: 'red' }} />
              )}
            </span>
          )}
          {delivery.withCutlery && (
            <span className="fa-stack mt-4" title="cutlery">
              <Icon name="cutlery" size={6} />
            </span>
          )}
          {delivery.withCoolerBag && (
            <span className="fa-stack mt-4" title="cooler Bag">
              <Icon name="coolerBag" size={28} className="w-16 h-16 -mb-16 mt-2" />
            </span>
          )}
        </div>

        <div>
          <p>
            <strong>Name</strong>
          </p>
          <p>
            {roles.includes(Permission.VIEW_DELIVERY_SUBSCRIPTION) ? (
              <Link to={Routes.subscription.replace(':id', delivery.userId)}>{delivery.name}</Link>
            ) : (
              delivery.name
            )}
          </p>

          <p>
            <strong>Phone</strong>
          </p>
          {roles.includes(Permission.VIEW_DELIVERY_SUBSCRIPTION) ? (
            <p>
              <PhoneNumber>{delivery.phoneNumber}</PhoneNumber>
            </p>
          ) : (
            delivery.phoneNumber
          )}

          <p>
            <strong>Address</strong>
          </p>
          <p>{AddressService.display(delivery.deliveryAddress)}</p>
          {delivery.deliveryAddress.notes && (
            <>
              <p>
                <strong>Notes</strong>
              </p>
              <p>{delivery.deliveryAddress.notes}</p>
            </>
          )}
          {delivery.driver && (
            <>
              <p>
                <strong>Driver</strong>
              </p>
              <p>{delivery.driver.name}</p>
            </>
          )}
        </div>
      </section>
      <Suspense fallback={<section className="element is-loading" />}>
        <MenuTab refetch={refetch} delivery={delivery} menuModalRef={menuModalRef} />
      </Suspense>
      {roles.includes(Permission.ADD_FOOD_DELIVERY_MENU) && (
        <span>
          <Button
            content="+Add a meal"
            className="my-4 border-black text-black focus: border-black hover:border-black"
            onClick={() => menuModalRef.current?.open()}
          />
        </span>
      )}
      <div className="flex justify-between mx-6 my-4">
        <DeliverySettingsCard delivery={delivery} onSave={updateMutation} />
        <div className="card">
          <header className="card-header bg-black">
            <p className="card-header-title text-white">Delivery Addresses</p>
          </header>
          <Suspense fallback={<section className="element is-loading" />}>
            <AddressTab delivery={delivery} />
          </Suspense>
        </div>
      </div>
    </>
  ) : (
    <section className="element is-loading" />
  );
};

export default ExactDelivery;
