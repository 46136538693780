import { getList } from 'actions';
import { ModalRef } from 'components';
import Popup from 'components/Popup';
import { useUserRoles } from 'lib/hooks';
import { CustomComponentsByCategory, Delivery, FoodComponent, FoodWithPosition, Menu as MenuFood } from 'lib/interfaces';
import { uniqBy } from 'lodash-es';
import { Fragment, useEffect, useRef, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import {
  CustomMealCategory,
  DietType,
  Food,
  FoodActionType,
  FoodComponentType,
  FoodDietType,
  FoodType,
  Subscription
} from '@calo/types';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, CircularProgress, Grid, Menu, MenuItem, Stack } from '@mui/material';

import { checkIsRemovedByDefault } from 'lib/helpers';
import ConfirmDeleteMealPopup from './ConfirmDeleteMealPopup';
import CustomizeMacrosPopUp from './CustomizeFoodMacrosPopUp';
import CustomizeMealCard from './CustomizeMealCard';
import SwapMealPopUp from './SwapMealPopUp';
import { styles } from './styles';
import { useMealActions } from './useMealActions';
interface DeliveryMealProps {
  delivery: Delivery;
  mealType: string;
  meal: FoodWithPosition;
  menu: MenuFood;
  menuLoading: boolean;
  subscription: Subscription;
  isCustomComponentsLoading: boolean;
  customFoodComponents: FoodComponent[];
  allCustomFoodComponents: CustomComponentsByCategory;
  caloKidsComponents: FoodComponent[];
  foodComponents: FoodComponent[];
  setCustomFoodComponents: React.Dispatch<React.SetStateAction<FoodComponent[]>>;
  refetchDelivery: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  setFetchMenu: (value: boolean) => void;
  setFetchAllComponents: (value: boolean) => void;
}

const DeliveryMeal = ({
  meal,
  menu,
  menuLoading,
  mealType,
  delivery,
  subscription,
  customFoodComponents,
  allCustomFoodComponents,
  caloKidsComponents,
  foodComponents,
  isCustomComponentsLoading,
  setCustomFoodComponents,
  refetchDelivery,
  setFetchMenu,
  setFetchAllComponents
}: DeliveryMealProps) => {
  const roles = useUserRoles();
  const foodModalRef = useRef<ModalRef>();
  const confirmModalRef = useRef<ModalRef>();
  const customFoodModalRef = useRef<ModalRef>();
  const removeComponentsRef = useRef<ModalRef>();
  const customizeMacrosModalRef = useRef<ModalRef>();

  const [customFood, setCustomFood] = useState<any | null>();
  const [selected, setSelected] = useState<FoodWithPosition | null | undefined>();
  const [selectedDeleteFood, setSelectedDeleteFood] = useState<Food>();
  const [selectedToCustomizeMacros, setSelectedToCustomizeMacros] = useState<FoodWithPosition | null>();
  const [customizeMealLoading, setCustomizeMealLoading] = useState<boolean>(false);

  const [selectedMealToRemoveComponents, setSelectedMealToRemoveComponents] = useState<Food>();
  const [selectedRemovedComponentIdList, setSelectedRemovedComponentIdList] = useState<string[]>([]);
  const [selectedSwappedComponent, setSelectedSwappedComponent] = useState<{ oldId: string; newId: string }[]>([]);
  const [selectedComponent, setSelectedComponent] = useState<FoodComponent | undefined>(undefined);

  const [deliveryActionMenuAnchorEl, setDeliveryActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isExportMenuOpened = Boolean(deliveryActionMenuAnchorEl);
  const isAddonSubscriptionFood = Boolean(meal?.addedAs && meal?.addedAs === 'addonSubscription');

  const [applyAlwaysForMeal, setApplyAlwaysForMeal] = useState<boolean>(false);
  const [previousApplyAlwaysForMeal, setPreviousApplyAlwaysForMeal] = useState<boolean>(applyAlwaysForMeal);

  const {
    isDisabled,
    skipIsDisabled,
    disabledDeleteMeal,
    handleCustomizeMeal,
    handleSkip,
    handleReplace,
    handleReplaceAddonSubscription
  } = useMealActions(delivery.id, refetchDelivery, selected, subscription, delivery, meal, mealType);

  const { data: foodPref, refetch } = useQuery<any, Error, any>([`food-preferences/${subscription?.id}`], getList, {
    suspense: false,
    enabled: !!subscription
  });

  useEffect(() => {
    const isRemovedByDefault = checkIsRemovedByDefault(foodPref, selectedMealToRemoveComponents);
    setApplyAlwaysForMeal(isRemovedByDefault);
  }, [delivery.id, selectedMealToRemoveComponents, foodPref]);

  useEffect(() => {
    if (previousApplyAlwaysForMeal && !applyAlwaysForMeal) {
      setSelectedRemovedComponentIdList([]);
      setSelectedSwappedComponent([]);
    }
    setPreviousApplyAlwaysForMeal(applyAlwaysForMeal);
  }, [applyAlwaysForMeal]);

  const uniqAllComponents: FoodComponent[] = uniqBy(Object.values(allCustomFoodComponents).flat(), 'id');

  useEffect(() => {
    const isPreBuilt = selectedMealToRemoveComponents?.tags.includes(FoodDietType.preBuiltCustom);
    if (isPreBuilt) {
      const modifiedComp = uniqAllComponents.filter((customComp) => {
        const mealSection = (customComp.sections ?? []).find((section) => section.category === CustomMealCategory.meal);
        return (
          mealSection?.tag ===
          (selectedComponent?.sections ?? []).find((section) => section.category === CustomMealCategory.meal)?.tag
        );
      });
      setCustomFoodComponents(modifiedComp || []);
    } else {
      setCustomFoodComponents(
        allCustomFoodComponents.meal.filter(
          (customComp) =>
            (customComp.sections ?? []).find((seciton) => seciton.category === CustomMealCategory.meal)?.tag ===
            FoodComponentType.sauce
        ) || []
      );
    }
  }, [selectedComponent, selectedMealToRemoveComponents]);

  useEffect(() => {
    if (selected) {
      setFetchAllComponents(true);
      setFetchMenu(true);
      foodModalRef.current?.open();
    } else {
      foodModalRef.current?.close();
    }
  }, [selected, customFood]);

  useEffect(() => {
    if (selectedToCustomizeMacros) {
      setFetchAllComponents(true);
      customizeMacrosModalRef.current?.open();
    } else {
      customizeMacrosModalRef.current?.close();
    }
  }, [selectedToCustomizeMacros, customFood]);

  const handleEditMeal = (item: FoodWithPosition) => {
    if (item.isCustom) {
      setCustomFood!(item);
      setSelected(item);
    } else {
      setSelected(item);
    }
  };

  const handleCustomizeMealMacros = (item: FoodWithPosition) => {
    if (item.isCustom) {
      setCustomFood!(item);
      setSelectedToCustomizeMacros(item);
    } else {
      setSelectedToCustomizeMacros(item);
    }
  };

  const handleEditMealMenuItem = () => {
    setSelectedMealToRemoveComponents(meal);
    setSelectedSwappedComponent(
      meal.actions
        ?.filter((action) => action.type === FoodActionType.swap)
        ?.flatMap((action) => action.swappedComponents || []) ?? []
    );
    setSelectedRemovedComponentIdList(
      meal.actions?.filter((action) => action.type === FoodActionType.remove)?.flatMap((action) => action.componentId || []) ?? []
    );
    setFetchAllComponents(true);
    setFetchMenu(true);
    removeComponentsRef.current?.open();
    setDeliveryActionMenuAnchorEl(null);
  };

  const handleSaveCustomize = async () => {
    setCustomizeMealLoading(true);
    try {
      await handleCustomizeMeal(
        selectedMealToRemoveComponents!.id,
        selectedRemovedComponentIdList,
        selectedSwappedComponent,
        applyAlwaysForMeal
      );
      removeComponentsRef.current?.close();
      setSelectedDeleteFood(undefined);
    } catch (error) {
      console.error('Error customizing meal:', error);
    } finally {
      refetch();
      setCustomizeMealLoading(false);
    }
  };

  const handleReplaceFunction = async (f: any) => {
    if (selected) {
      await handleReplace(selected.id, f.id, selected.positionIndex);
    }
  };

  return (
    <>
      <Fragment>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <LoadingButton
            loading={false}
            id="delivery-action-button"
            aria-haspopup="true"
            disableElevation
            onClick={(event) => {
              !deliveryActionMenuAnchorEl && setDeliveryActionMenuAnchorEl(event.currentTarget);
            }}
            variant="outlined"
            aria-label="meal-actions"
            sx={styles.actionbutton}
            endIcon={<ArrowDownIcon />}
            disabled={!meal.name || mealType === 'giftItem'}
          >
            Action
          </LoadingButton>
          <Menu
            MenuListProps={{
              'aria-labelledby': 'export-button'
            }}
            anchorEl={deliveryActionMenuAnchorEl}
            open={isExportMenuOpened}
            onClose={() => setDeliveryActionMenuAnchorEl(null)}
          >
            {roles.includes(Permission.REPLACE_FOOD_DELIVERY_MENU) && !meal.skipped && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                disabled={isAddonSubscriptionFood ? false : mealType !== 'meal'}
                onClick={() => {
                  handleEditMeal(meal);
                  setDeliveryActionMenuAnchorEl(null);
                }}
              >
                <Box>Replace Meal</Box>
              </MenuItem>
            )}
            {roles.includes(Permission.REPLACE_FOOD_DELIVERY_MENU) &&
              !meal.skipped &&
              delivery.plan.dietType === DietType.customMacros && (
                <MenuItem
                  sx={{ fontWeight: 600, py: 1, mr: 2 }}
                  disabled={isAddonSubscriptionFood ? false : mealType !== 'meal'}
                  onClick={() => {
                    handleCustomizeMealMacros(meal);
                    setDeliveryActionMenuAnchorEl(null);
                  }}
                >
                  <Box>Customize Macros</Box>
                </MenuItem>
              )}
            {roles.includes(Permission.DELETE_FOOD_DELIVERY_MENU) && !meal.skipped && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                onClick={() => {
                  setSelectedDeleteFood(meal);
                  confirmModalRef.current?.open();
                  setDeliveryActionMenuAnchorEl(null);
                }}
                disabled={disabledDeleteMeal}
              >
                <Box>Delete Meal</Box>
              </MenuItem>
            )}
            {roles.includes(Permission.DELETE_FOOD_DELIVERY_MENU) && (
              <MenuItem
                onClick={() => {
                  handleSkip(meal.skipped ?? false, meal.id);
                  setDeliveryActionMenuAnchorEl(null);
                }}
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                disabled={skipIsDisabled}
              >
                <Box>{meal.skipped ? 'Un-Skip Meal' : 'Skip Meal'}</Box>
              </MenuItem>
            )}
            {roles.includes(Permission.REMOVE_COMPONENT_FROM_DELIVERY_FOOD) && !meal.skipped && (
              <MenuItem sx={{ fontWeight: 600, py: 1, mr: 2 }} onClick={handleEditMealMenuItem} disabled={isDisabled}>
                <Box>Edit Meal</Box>
              </MenuItem>
            )}
          </Menu>
        </Grid>
        <ConfirmDeleteMealPopup
          ref={confirmModalRef}
          delivery={delivery}
          selectedDeleteFood={selectedDeleteFood}
          handleClose={() => confirmModalRef.current?.close()}
          setSelectedDeleteFood={setSelectedDeleteFood}
          refetchDelivery={refetchDelivery}
        />
        {selectedToCustomizeMacros && (
          <CustomizeMacrosPopUp
            refetchDelivery={refetchDelivery}
            food={selectedToCustomizeMacros!}
            customizeMacrosModalRef={customizeMacrosModalRef}
            onClose={() => {
              setSelectedToCustomizeMacros(undefined);
              customizeMacrosModalRef.current?.close();
            }}
            deliveryId={delivery.id}
            subscription={subscription}
          />
        )}

        <SwapMealPopUp
          menu={menu}
          key={selected?.id}
          delivery={delivery}
          selectedMeal={selected!}
          menuLoading={menuLoading}
          foodModalRef={foodModalRef}
          setSelectedMeal={setSelected}
          handleReplace={handleReplaceFunction}
          customFoodModalRef={customFoodModalRef}
          customFoodComponents={allCustomFoodComponents}
          handleClose={() => foodModalRef.current?.close()}
          isCustomComponentsLoading={isCustomComponentsLoading}
          handleReplaceAddonSubscriptionFood={handleReplaceAddonSubscription}
        />

        <Popup
          fullWidth
          maxWidth="md"
          ref={removeComponentsRef}
          title={'Customize Meal'}
          onClose={() => removeComponentsRef.current?.close()}
        >
          <>
            <Stack sx={{ mt: '-16px' }}>
              <CustomizeMealCard
                key={meal.id}
                components={foodComponents}
                applyAlwaysForMeal={applyAlwaysForMeal}
                setApplyAlwaysForMeal={setApplyAlwaysForMeal}
                customFoodComponents={customFoodComponents}
                setCustomFoodComponents={setCustomFoodComponents}
                selectedSwappedComponent={selectedSwappedComponent}
                setSelectedSwappedComponent={setSelectedSwappedComponent}
                selectedRemovedComponentIdList={selectedRemovedComponentIdList}
                setSelectedRemovedComponentIdList={setSelectedRemovedComponentIdList}
                isPreBuiltMeal={meal.tags?.includes(FoodDietType.preBuiltCustom)}
                selectedComponent={selectedComponent}
                setSelectedComponent={(comp) => setSelectedComponent(comp)}
                allCustomFoodComponents={allCustomFoodComponents}
                caloKidsComponents={meal.type?.includes(FoodType.caloKids) ? caloKidsComponents : []}
                isLoading={isCustomComponentsLoading}
              />
            </Stack>

            <Stack flexDirection={'row'} justifyContent={'end'} sx={{ mt: 3 }}>
              <Button
                sx={styles.cancelButton}
                onClick={() => {
                  removeComponentsRef.current?.close();
                  setSelectedDeleteFood(undefined);
                }}
                disabled={customizeMealLoading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                aria-label="confirm-remove-meal"
                sx={styles.saveButton}
                disabled={customizeMealLoading}
                endIcon={customizeMealLoading ? <CircularProgress size={20} /> : null}
                onClick={handleSaveCustomize}
              >
                Save
              </Button>
            </Stack>
          </>
        </Popup>
      </Fragment>
    </>
  );
};

export default DeliveryMeal;
