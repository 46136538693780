export const simpleFoodListQuery = /* GraphQL */ `
  query GET_FOOD_LIST_DETAILS($query: GetFoodReqInput) {
    listFoodDetails(query: $query) {
      meta {
        limit
        total
      }
      data {
        averageRating
        numberOfRatings
        totalRating
        periodicAggregatedRatings {
          periodInDays
          total
          count
          average
        }
        country
        kitchen
        description {
          en
        }
        id
        name {
          en
        }
        size
        slug
        tags
        type
        metadata
      }
    }
  }
`;
