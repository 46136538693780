import { FoodFilters, FoodStatus, Permission, SortingFood } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';
import { Icon as Iconify } from '@iconify/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  IconButton,
  Button as MUIButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';
import { exportFood, getListWithParams } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Pagination } from 'components';
import Icon from 'components/Icon';
import { Routes } from 'lib/enums';
import { resolveCountryFromKitchen } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { Food } from 'lib/interfaces';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import FoodRow from './FoodRow';
import Settings from './Settings';
import { simpleFoodListQuery } from './query';

interface PaginatedFoodList {
  data: Food[];
  meta: {
    limit: number;
    total: number;
  };
}

const FoodList = () => {
  const history = useHistory();
  const roles = useUserRoles();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const userKitchen = useUserKitchens();
  const searchParams = new URLSearchParams(location.search);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const options = ['Create Meal', 'Regular Meal', 'Custom Meal'];
  const [open, setOpen] = useState(false);

  const [filters, setFilters] = useState<FoodFilters>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    isDeleted: false,
    status: FoodStatus.approved,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });
  const [sort, setSort] = useState<SortingFood>({
    orderBy: 'name',
    orderMode: 'asc'
  });
  const isDisabled = false;

  const { data, isLoading } = useQuery<any, Error, PaginatedFoodList>(
    [
      'food',
      {
        filters: {
          brand: filters.brand,
          country: filters.country,
          kitchen: filters.kitchen,
          foodType: filters.foodType,
          dietType: filters.dietType,
          name: filters.name,
          componentId: filters.componentId ? filters.componentId : undefined,
          ingredientId: filters.ingredientId ? filters.ingredientId : undefined,
          foodTags: filters.foodTags,
          sizes: filters.sizes,
          status: filters.status,
          isDeleted: false
        },
        sort,
        page,
        limit: 50,
        query: simpleFoodListQuery
      }
    ],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  const foodList = useMemo(
    () =>
      (data?.data || []).filter((f) => {
        let res = true;
        if (filters.dietType) {
          res = res && (f.tags || []).includes(filters.dietType);
        }
        if (filters.foodType) {
          res = res && f.type.includes(Array.isArray(filters.foodType) ? filters.foodType[0] : filters.foodType);
        }

        return res;
      }),
    [filters, data]
  );

  useEffect(() => {
    setPage(0);
  }, [filters]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    if (index < 1) {
      history.push(Routes.newFood.replace(':type', 'regular'));
    } else {
      history.push(Routes.newFood.replace(':type', 'custom'));
    }
    setOpen(false);
  };

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1} sx={{ mb: 2 }}>
        <Typography
          variant="h3"
          sx={{
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column',
              textAlign: 'center'
            },
            textAlign: 'left',
            fontSize: '28px',
            lineHeight: '32px',
            mt: '2px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Meals
        </Typography>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
          {roles.includes(Permission.EXPORT_FOOD_LIST) && (
            <IconButton
              sx={{
                border: 1,
                borderColor: caloTheme.palette.grey[300],
                borderRadius: '6px',
                width: '40px',
                height: '40px',
                backgroundColor: caloTheme.palette.white
              }}
              onClick={() => exportFood(sort, filters)}
            >
              <Iconify color={caloTheme.palette.black} width={30} icon="uil:file-export" />
            </IconButton>
          )}
          {roles.includes(Permission.CREATE_FOOD) && (
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
              <ButtonGroup
                ref={anchorRef}
                aria-label="split button"
                disabled={isDisabled}
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 600,
                  border: 1,
                  height: '41px',
                  borderRadius: '8px',
                  borderColor: caloTheme.palette.primary500,
                  color: caloTheme.palette.white,
                  backgroundColor: caloTheme.palette.primary500,
                  ':hover': {
                    color: caloTheme.palette.primary500,
                    backgroundColor: caloTheme.palette.white,
                    border: 1,
                    borderRadius: '8px'
                  },
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    width: '100%',
                    textAlign: 'center',
                    flexDirection: 'row'
                  }
                }}
              >
                <MUIButton
                  onClick={() => {
                    if (selectedIndex < 1) {
                      history.push(Routes.newFood.replace(':type', 'regular'));
                    } else {
                      history.push(Routes.newFood.replace(':type', 'custom'));
                    }
                  }}
                  data-test="createFoodButton"
                  sx={{
                    textTransform: 'capitalize',
                    borderColor: caloTheme.palette.primary500,
                    color: caloTheme.palette.white,
                    backgroundColor: caloTheme.palette.primary500,
                    pr: 1,
                    fontSize: '19px',
                    lineHeight: '23px',
                    fontWeight: 600,
                    ':hover': {
                      color: caloTheme.palette.primary500,
                      backgroundColor: caloTheme.palette.white,
                      border: 1,
                      borderRadius: '8px'
                    },
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      width: '100%',
                      textAlign: 'center',
                      flexDirection: 'row'
                    }
                  }}
                >
                  {options[selectedIndex]}
                </MUIButton>
                <MUIButton
                  size="small"
                  aria-controls={'split-MUIButton-menu'}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select copy language"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                  sx={{
                    borderColor: caloTheme.palette.primary500,
                    color: caloTheme.palette.white,
                    backgroundColor: caloTheme.palette.primary500,
                    px: 0,
                    ':hover': {
                      color: caloTheme.palette.primary500,
                      backgroundColor: caloTheme.palette.white,
                      border: 1,
                      borderRadius: '8px'
                    }
                  }}
                >
                  <ArrowDropDownIcon />
                </MUIButton>
              </ButtonGroup>
              <Popper
                sx={{
                  width: '170px',
                  zIndex: 1,
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    width: 'auto'
                  }
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                    }}
                  >
                    <Paper
                      sx={{
                        width: '100%'
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          id="split-button-menu"
                          autoFocusItem
                          sx={{
                            width: '100%',
                            mt: 1
                          }}
                        >
                          {options
                            .filter((o) => o !== options[selectedIndex])
                            .map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={() => handleMenuItemClick(index)}
                                sx={{
                                  fontFamily: caloTheme.typography.fontFamily,
                                  fontWeight: 600,
                                  fontSize: '16px',
                                  lineHeight: '19px',
                                  flexGrow: 0,
                                  width: '100%',
                                  pb: 1,
                                  textTransform: 'capitalize',
                                  justifyContent: 'center'
                                }}
                              >
                                {option}
                              </MenuItem>
                            ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Stack>
          )}
        </Stack>
      </Stack>
      <section>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <div className="card has-table has-table-container-upper-radius">
            <div className="card-content">
              <div>
                <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                  <thead>
                    <tr style={{ backgroundColor: '#303030' }}>
                      <th
                        onClick={() => {
                          setSort({ ...sort, orderBy: 'name', orderMode: sort.orderMode === 'asc' ? 'desc' : 'asc' });
                        }}
                        style={{ color: 'white' }}
                        className="cursor-pointer"
                      >
                        <span className="flex">
                          <p>Name</p>
                          <Icon name="sort" size={4} className="ml-1 mt-1" />
                        </span>
                      </th>
                      {filters.status !== FoodStatus.draft && (
                        <th
                          className="cursor-pointer"
                          onClick={() => {
                            setSort({
                              ...sort,
                              orderBy: 'rating',
                              orderMode: sort.orderMode === 'desc' ? 'asc' : 'desc'
                            });
                          }}
                          style={{ color: 'white' }}
                        >
                          <span className="flex">
                            <p>Rating</p>
                            <Icon name="sort" size={4} className="ml-1 mt-1" />
                          </span>
                        </th>
                      )}
                      <th style={{ color: 'white' }}>Favorites</th>
                      <th style={{ color: 'white' }}>Blocked</th>
                      <th style={{ color: 'white' }}>Description</th>
                      <th style={{ color: 'white' }}>Country</th>
                      <th style={{ color: 'white' }}>Kitchen</th>
                      <th style={{ color: 'white' }}>Type</th>
                      <th style={{ color: 'white' }}>Tags</th>
                    </tr>
                  </thead>
                  {data && data.data.length === 0 ? (
                    <span className="absolute w-full text-3xl mt-4 text-center font-bold text-gray-400 ">NO MEALS</span>
                  ) : (
                    <tbody>
                      {foodList.map((food, index) => (
                        <FoodRow index={index} key={food.id} food={food} isDraft={filters.status === FoodStatus.draft} />
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
              {data && (
                <Pagination
                  page={page}
                  onChange={setPage}
                  limit={data.meta.limit}
                  total={data.meta.total}
                  isHidden={data.data.length === 0}
                />
              )}
            </div>
          </div>
        )}
      </section>
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default FoodList;
