import { quickSearch, toggleUICouponSettings, toggleUIKitchenSettings, toggleUISettings } from 'actions';
import cx from 'classnames';
import { format } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { groupBy, map } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { Brand } from '@calo/types';

import { Autocomplete } from '../';
import Icon from '../Icon';

interface TopBarProps {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}

const TopBar = ({ setIsOpen, isOpen }: TopBarProps) => {
  const dispatch = useDispatch();
  const roles = useUserRoles();

  const history = useHistory();

  const handlePickSearch = (data: any) => {
    if (data.type === 'delivery') {
      history.push(Routes.delivery.replace(':id', data.value));
    } else {
      const link = `${Routes.subscription.replace(':id', data.value)}/?tab=Deliveries&delivery=${format('yyyy-MM-dd')(Date.now())}`;
      history.push(link);
    }
  };

  return (
    <div
      className={cx('flex flex-row h-16 bg-white fixed top-0 right-0 left-0 z-50 items-center', {
        // 'w-0 hidden': !isOpen,
        'ml-56': isOpen
      })}
    >
      <div className="px-6">
        <a className="navbar-item is-hidden-desktop" onClick={() => setIsOpen(!isOpen)}>
          <i className="fas fa-bars"></i>
        </a>
      </div>
      <div className="flex-1 flex-grow px-6">
        {roles.includes(Permission.VIEW_SEARCH_BAR) && (
          <Autocomplete
            onSearch={(text) =>
              quickSearch({
                limit: 10,
                query: text,
                page: 1
              })
            }
            transformer={(data: any[]) =>
              map(groupBy(data, 'type'), (group, key) => ({
                label: key,
                options: group.map((row: any) =>
                  row.type === 'delivery'
                    ? {
                        value: row.id,
                        type: row.type,
                        label: `${row.name}, ${row.phoneNumber}, ${row.day}, ${row.brand ? row.brand : Brand.CALO}`
                      }
                    : {
                        value: row.id,
                        type: row.type,
                        label: `${row.name}, ${row.phoneNumber}, ${row.email}, ${row.brand ? row.brand : Brand.CALO}`
                      }
                )
              }))
            }
            placeHolder={false}
            onPick={(data) => handlePickSearch(data)}
          />
        )}
      </div>
      <div className="px-6 flex-row">
        {useRouteMatch({
          path: [
            Routes.deliveries,
            Routes.root,
            Routes.driversMetric,
            Routes.coolerBags,
            Routes.addonsList,
            Routes.foodList,
            Routes.subscriptions,
            Routes.usersList,
            Routes.refunds,
            Routes.couponsList,
            Routes.coupon,
            Routes.payment,
            Routes.drivers,
            Routes.invitationStats,
            Routes.kitchen,
            Routes.kdsUsersList,
            Routes.foodLogs,
            Routes.gifts,
            Routes.supplyChainUsersList,
            Routes.addonsSubscriptionList,
            Routes.playgroundFoodList,
            Routes.playgroundComponentList,
            Routes.playgroundIngredientList
          ],
          exact: true
        }) && (
          <a className="navbar-item" data-test="filterButton" onClick={() => dispatch(toggleUISettings())}>
            <i className="fas fa-filter fa-lg" id="filter"></i>
          </a>
        )}

        {useRouteMatch({
          path: [Routes.coupon],
          exact: true
        }) && (
          <a className="navbar-item" onClick={() => dispatch(toggleUICouponSettings())}>
            <Icon name="settings" size={8} />
          </a>
        )}

        {useRouteMatch({
          path: [Routes.kitchen],
          exact: true
        }) && (
          <a className="navbar-item" onClick={() => dispatch(toggleUIKitchenSettings())}>
            <Icon name="settings" size={8} />
          </a>
        )}
      </div>
    </div>
  );
};

export default TopBar;
