import { Brand, Country, Kitchen } from '@calo/types';
import { getListWithParams } from 'actions/index';
import { Food } from 'lib/interfaces';
import { useQuery } from 'react-query';

interface UseFoodListProps {
  country: Country;
  brand: Brand;
  kitchen: Kitchen;
  filterName?: string;
}

const useFoodList = ({ country, brand, kitchen, filterName }: UseFoodListProps) => {
  const { data: foodList, isLoading: foodLoading } = useQuery<any, Error, { data: Food[] }>(
    [
      'food',
      {
        filters: {
          name: filterName,
          country,
          brand,
          kitchen,
          isDeleted: false
        },
        calculatePurchasingCost: true,
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 20
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: !!filterName
    }
  );
  return {
    foodList,
    foodLoading
  };
};
export default useFoodList;
