import {
  AddDeliveryAddonReq,
  AssignOrderMenuReq,
  CreateDeliveryReq,
  DeliveryFilters,
  ReplaceFoodReq,
  UpdateDeliveryReq
} from '@calo/dashboard-types';
import { DeliveryStatus, FoodActions } from '@calo/types';

import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import { BusinessOrderFilters } from 'lib/interfaces';
import mutation from './mutation';

export interface skipMealProps {
  deliveryId: string;
  foodId: string;
}
export const createSubscriptionDelivery = async (values: CreateDeliveryReq & { id: string }) => {
  const { id, ...rest } = values;
  const { data, status } = await client.post(`/subscriptions/${id}/deliveries`, rest);
  ToastSuccessMsgs({ status, action: 'New Delivery Created' });
  return data;
};

export const updateDelivery = async (payload: UpdateDeliveryReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/deliveries/${id}`, rest);
  mutation(['deliveries', id], data);
  if (payload.status) {
    if (payload.status === DeliveryStatus.paymentRequired) {
      ToastSuccessMsgs({ status, action: 'Delivery un-cancelled' });
    } else {
      ToastSuccessMsgs({ status, action: `Delivery ${payload.status}` });
    }
  } else if (payload.time) {
    ToastSuccessMsgs({ status, action: `Delivery Time changed to ${payload.time}` });
  } else {
    ToastSuccessMsgs({ status, action: 'Delivery Address changed' });
  }
};
export const skipMeal = async ({ deliveryId, foodId }: skipMealProps) => {
  const { data, status } = await client.put(`/deliveries/${deliveryId}/food/${foodId}/skip`);
  mutation(['deliveries', deliveryId], data);
  ToastSuccessMsgs({ status, action: `Meal skipped successfully` });
};

export const unSkipMeal = async ({ deliveryId, foodId }: skipMealProps) => {
  try {
    const { data, status } = await client.put(`/deliveries/${deliveryId}/food/${foodId}/unskip`);
    ToastSuccessMsgs({ status, action: `Meal unskipped successfully` });
    mutation(['deliveries', deliveryId], data);
  } catch (error) {
    console.error(error);
  }
};
export const skipDelivery = async (id: string) => {
  const { data, status } = await client.post(`/deliveries/${id}/skip`);
  mutation(['deliveries', id], data);
  ToastSuccessMsgs({ status, action: 'Delivery Skipped' });
};

export const unskipDelivery = async (id: string) => {
  const { data, status } = await client.post(`/deliveries/${id}/unskip`);
  mutation(['deliveries', id], data);
  ToastSuccessMsgs({ status, action: 'Delivery unSkipped' });
};

export const cancelDelivery = async (id: string) => {
  const { data, status } = await client.post(`/deliveries/${id}/cancel`);
  mutation(['deliveries', id], data);
  ToastSuccessMsgs({ status, action: 'Delivery cancelled' });
};

export const uncancelDelivery = async (id: string) => {
  const { data, status } = await client.post(`/deliveries/${id}/uncancel`);
  mutation(['deliveries', id], data);
  ToastSuccessMsgs({ status, action: 'Delivery un-cancelled' });
};

export const replaceFood = async (payload: ReplaceFoodReq & { id: string; positionIndex: number }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.post(`/deliveries/${id}/replace-food`, rest);
  ToastSuccessMsgs({ status, action: 'Food replaced successfully' });
  return data;
};

export const addAddonFood = async (payload: AddDeliveryAddonReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.post(`deliveries/${id}/addons`, rest);
  ToastSuccessMsgs({ status, action: 'added successfully' });
  return data;
};

export const addMenuFood = async (payload: any & { deliveryId: string; foodId: string }) => {
  const { deliveryId, foodId } = payload;
  const { data, status } = await client.post(`deliveries/${deliveryId}/food`, {
    foodId: foodId
  });
  ToastSuccessMsgs({ status, action: 'added successfully' });
  return data;
};

export const deleteFoodMenu = async (payload: { id: string; foodId: string }) => {
  const { id, foodId } = payload;
  const { data, status } = await client.delete(`deliveries/${id}/food/${foodId}`);
  ToastSuccessMsgs({ status, action: 'removed successfully' });
  return data;
};
export const deleteAddonMenu = async (payload: { id: string; addonId: string }) => {
  const { id, addonId } = payload;
  const { data, status } = await client.delete(`deliveries/${id}/addons/${addonId}`);
  ToastSuccessMsgs({ status, action: 'removed successfully' });
  return data;
};

export const regenerateDeliveryMenu = async (payload: AssignOrderMenuReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.post(`/deliveries/${id}/regenerate-menu`, rest);
  ToastSuccessMsgs({ status, action: 'Menu generated successfully' });
  return data;
};

export const getDeliveriesStats = async (day: string) => {
  const { data } = await client.get('/stats/delivery', { params: { day } });
  return data;
};

export const updatePaymentDelivery = async ({ id }: { id: string }) => {
  const { data } = await client.post(`/deliveries/${id}/pay`);
  mutation(['deliveries', id], data);
  return data;
};

export const getInvoiceForDelivery = async (id: string) => {
  const res = await client.post(`/deliveries/${id}/invoice`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf'
    }
  });
  return res;
};

export const exportDeliveries = async (filters: DeliveryFilters) => {
  const { data, status } = await client.get('deliveries/export', { params: filters });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
  return data;
};

export const customizeMeal = async (payload: {
  deliveryId: string;
  foodId: string;
  actions: FoodActions[];
  applyAlwaysForMeal: boolean;
}) => {
  const { deliveryId, foodId, actions, applyAlwaysForMeal } = payload;
  const { status } = await client.put(`deliveries/${deliveryId}/food/${foodId}/modify-components`, {
    actions,
    applyAlwaysForMeal
  });
  ToastSuccessMsgs({ status, action: 'Meal Customized Successfully' });
};

export const exportBusinessDeliveries = async (filters: BusinessOrderFilters): Promise<any[]> => {
  console.log(filters);
  alert('need the export endpoint');
  // const { data, status } = await client.get('deliveries/export', { params: filters });
  // ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
  // return data;
  return [];
};
